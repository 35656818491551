import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";

// Component imports
import Hero from "../components/Hero";
import EdgeSection from "../components/EdgeSection";
import Features from "../components/Features";
import Pricing from "../components/Pricing";
import Footer from "../components/Footer";
import EOI from "../components/EOI";
import BGAnimation from "../components/BGAnimation";
import DayPass from '../components/DayPass';
import SocialLinks from '../components/SocialLinks'; // New component import

// CSS imports
import "../css/EOI.css";
import "../css/BGAnimation.css";

function LandingPage() {
  const [showEOI, setShowEOI] = useState(false);
  const location = useLocation();

  // Handle URL parameters for scrolling to sections
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const section = params.get("section");

    if (section) {
      scroller.scrollTo(section, {
        duration: 800,
        smooth: "easeInOutQuart",
      });
    }
  }, [location]);

  // Show the EOI popup after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowEOI(true);
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <BGAnimation />
      <div className={`landing-container ${showEOI ? "blur-background" : ""}`}>
        <Hero />
        <EdgeSection />
        <Features id="features" />
        <Pricing id="pricing" />
        <DayPass />
        <SocialLinks /> 
        <Footer />
      </div>
      {showEOI && <EOI setShowPopup={setShowEOI} />}
    </>
  );
}

export default LandingPage;