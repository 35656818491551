import React from 'react';
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Area,
    AreaChart
} from 'recharts';
import '../css/Performance/ProfitLossGraph.css';
import { format, parseISO, addDays } from 'date-fns';

const ProfitLossGraph = ({ data, unitBet }) => {
    // Map and format data into weekly profit/loss
    const graphData = data.map(row => ({
        date: format(parseISO(row.Games.GameDate), 'yyyy-MM-dd'),
        profitLoss: (row.Outcome === 'yes' ? parseFloat(row.OddsValue) - 1 : -1) * unitBet, // Multiply by unitBet
    }));

    // Group data by week
    const weeklyData = graphData.reduce((acc, curr) => {
        const weekStart = format(addDays(parseISO(curr.date), -parseISO(curr.date).getDay()), 'yyyy-MM-dd'); // Start of the week
        const existingWeek = acc.find(item => item.weekStart === weekStart);
        if (existingWeek) {
            existingWeek.profitLoss += curr.profitLoss;
        } else {
            acc.push({ weekStart, profitLoss: curr.profitLoss });
        }
        return acc;
    }, []);
    
    // Sort weekly data by weekStart date
    weeklyData.sort((a, b) => new Date(a.weekStart) - new Date(b.weekStart));
    
    // Calculate cumulative profit
    let cumulativeProfit = 0;
    const cumulativeData = weeklyData.map(item => {
        cumulativeProfit += item.profitLoss;
        return { ...item, cumulativeProfit };
    });
    
    // Filter to the last 12 weeks
    const filteredData = cumulativeData.slice(-12);

    return (
        <div className="profit-loss-container">
            <h2 className="graph-title">Weekly Profit/Loss</h2>
            <ResponsiveContainer width="100%" height={300}>
                <AreaChart data={filteredData} margin={{ top: 20, right: 30, left: 20, bottom: 0 }}>
                    <defs>
                        <linearGradient id="profitLossGradient" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#82ca9d" stopOpacity={0.8} /> {/* Green */}
                            <stop offset="100%" stopColor="#ff6b6b" stopOpacity={0.8} /> {/* Red */}
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" stroke="#2e2e2e" />
                    <XAxis
                        dataKey="weekStart"
                        tick={{ fill: '#b0b0b0' }}
                        tickFormatter={(date) => format(parseISO(date), 'MMM d')} // Format week start dates
                    />
                    <YAxis tick={{ fill: '#b0b0b0' }} />
                    <Tooltip contentStyle={{ backgroundColor: '#333', color: '#fff', borderRadius: '5px' }} />
                    <Area
                        type="monotone"
                        dataKey="cumulativeProfit"
                        stroke="#82ca9d"
                        strokeWidth={2}
                        fill="url(#profitLossGradient)"
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ProfitLossGraph;