import '../css/Features-Pricing.css';
import React from 'react';

function Pricing() {
  const pricingPlans = [
    {
      title: 'Weekly',
      price: '$27/wk',
      description: 'Perfect for casual bettors looking for basic insights.',
      features: [
        'Full access to our AFL predictions',
        'Bet tracking tools and risk profile calculators',
        'Community support',
      ],
    },
    {
      title: 'Monthly',
      price: '$77/mo',
      description: 'Advanced stats and insights for serious bettors.',
      features: [
        'Full access to our AFL predictions',
        'Bet tracking tools and risk profile calculators',
        'Access to our multibet creator algorithms',
        'Community support',
      ],
      recommended: true, // Flag for recommended plan
    },
    {
      title: 'Yearly',
      price: '$770/yr',
      description: 'Comprehensive insights for professional bettors.',
      features: [
        'Full access to our AFL predictions',
        'Bet tracking tools and risk profile calculators',
        'Access to our multibet creator algorithms',
        'Community support',
      ],
    },
  ];

  return (
    <section id="pricing" className="pricing-section">
      {pricingPlans.map((plan, index) => (
        <div key={index} className="pricing-card-wrapper">
          {/* recommended badge */}
          {plan.recommended && (
            <div className="badge"> Recommended </div>
          )}
          <div className={`pricing-card ${plan.recommended ? 'recommended' : ''}`}>
            {plan.recommended && (
              <>
                <div className="bg"></div>
                <div className="blob"></div>
              </>
            )}
            <h3>{plan.title}</h3>
            <strong>{plan.price}</strong>
            <p>{plan.description}</p>
            <ul>
              {plan.features.map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>
            <div style={{ height: '40px' }}></div> {/* Placeholder to preserve layout */}
          </div>
        </div>
      ))}
    </section>
  );
}

export default Pricing;
