import React from "react";
import "../css/Performance/RiskAnalysis.css";

const RiskAnalysis = ({ data }) => {
    console.log(data)
    // Aggregate data into risk bands
    const riskBands = {
        high: { bets: 0, wins: 0, totalWagered: 0, marketCounts: {} },
        medium: { bets: 0, wins: 0, totalWagered: 0, marketCounts: {} },
        low: { bets: 0, wins: 0, totalWagered: 0, marketCounts: {} },
    };

    let totalPrediction = 0;

    data.forEach((bet) => {
        const { Model_probability, Outcome, OddsValue, StatType } = bet;
        totalPrediction += Model_probability;

        let band = null;
        if (Model_probability < 65) band = "high";
        else if (Model_probability < 80) band = "medium";
        else band = "low";

        if (band) {
            riskBands[band].bets += 1;
            riskBands[band].totalWagered += parseFloat(OddsValue);
            if (Outcome === "yes") {
                riskBands[band].wins += 1;
            }
            riskBands[band].marketCounts[StatType] =
                (riskBands[band].marketCounts[StatType] || 0) + 1;
        }
    });

    // Calculate average prediction
    const averagePrediction = (totalPrediction / data.length).toFixed(2);

    // Calculate ROI and most common markets
    const formatBand = (band) => {
        const { bets, wins, totalWagered, marketCounts } = band;
        const winRate = bets ? ((wins / bets) * 100).toFixed(2) : 0;
        const roi = bets ? (((wins * 2 - bets) / bets) * 100).toFixed(2) : 0; // Example ROI calculation
        const mostCommonMarket = Object.entries(marketCounts).sort(
            ([, a], [, b]) => b - a
        )[0]?.[0]?.replaceAll("_", " ") || "N/A"; // Replace underscores with spaces
        return { bets, winRate, roi, mostCommonMarket };
    };

    const highRisk = formatBand(riskBands.high);
    const mediumRisk = formatBand(riskBands.medium);
    const lowRisk = formatBand(riskBands.low);

    return (
        <div className="risk-analysis-container">
            <h2 className="risk-analysis-title">Risk Analysis</h2>
            <p className="average-prediction">
                Average Prediction: <strong>{averagePrediction}%</strong>
            </p>
            <div className="risk-band">
                <h3 className="risk-band-title high-risk">High Risk</h3>
                <p>{highRisk.bets} bets</p>
                <p>Win Rate: {highRisk.winRate}%</p>
                <p>ROI: {highRisk.roi}%</p>
                <p>Most Common Market: {highRisk.mostCommonMarket}</p>
            </div>
            <div className="risk-band">
                <h3 className="risk-band-title medium-risk">Medium Risk</h3>
                <p>{mediumRisk.bets} bets</p>
                <p>Win Rate: {mediumRisk.winRate}%</p>
                <p>ROI: {mediumRisk.roi}%</p>
                <p>Most Common Market: {mediumRisk.mostCommonMarket}</p>
            </div>
            <div className="risk-band">
                <h3 className="risk-band-title low-risk">Low Risk</h3>
                <p>{lowRisk.bets} bets</p>
                <p>Win Rate: {lowRisk.winRate}%</p>
                <p>ROI: {lowRisk.roi}%</p>
                <p>Most Common Market: {lowRisk.mostCommonMarket}</p>
            </div>
        </div>
    );
};

export default RiskAnalysis;