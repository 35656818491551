import React, { useState } from 'react';
import '../css/Performance/UnitBetInput.css';

const UnitBetInput = ({ unitBet, setUnitBet }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [tempUnitBet, setTempUnitBet] = useState(unitBet);

    const handleSave = () => {
        setUnitBet(tempUnitBet);
        setIsEditing(false);
    };

    return (
        <div className="unit-bet-container">
            {isEditing ? (
                <div className="unit-bet-input">
                    <input
                        type="number"
                        value={tempUnitBet}
                        onChange={(e) => setTempUnitBet(Number(e.target.value))}
                    />
                    <button onClick={handleSave} className="save-button">Save</button>
                </div>
            ) : (
                <div className="unit-bet-display">
                    <p>{`$${unitBet.toFixed(2)} per unit`}</p>
                    <button onClick={() => setIsEditing(true)} className="edit-button">Edit</button>
                </div>
            )}
        </div>
    );
};

export default UnitBetInput;