import React, { useState } from 'react';
import '../css/Edge.css';
import EOI from "../components/EOI"; // Import the EOI component

function EdgeSection() {
  // State to manage popup visibility
  const [showPopup, setShowPopup] = useState(false);

  return (
    <section id="about" className="edge-section">
      <div className="edge-content">
        <h2>FIND YOUR EDGE</h2>
        <p>
          Gain a decisive edge over the bookmakers by finding targeted picks
          showing the best value across different sporting events.
        </p>

        {/* Render EOI only when showPopup is true */}
        {showPopup && <EOI setShowPopup={setShowPopup} />}

        {/* Button to trigger the EOI popup */}
        <button className="edge-button" onClick={() => setShowPopup(true)}>
          Learn More
        </button>
      </div>

      <div className="edge-video">
        <iframe
          src="https://www.youtube.com/embed/6Y1uipOzcfo"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </section>
  );
}

export default EdgeSection;
