import React from 'react';
import '../css/Performance/TotalProfitLoss.css';

const TotalProfitLoss = ({ data, unitBet }) => {
    // Calculate total profit/loss
    const totalProfitLoss = data.reduce((total, bet) => {
        const profitLoss =
            bet.Outcome === 'yes'
                ? (parseFloat(bet.OddsValue) - 1) * unitBet
                : -1 * unitBet;
        return total + profitLoss;
    }, 0);

    // Format the number for better readability
    const formattedProfitLoss = totalProfitLoss.toLocaleString(undefined, {
        minimumFractionDigits: 2,
    });

    return (
        <div className="total-profit-loss">
            <div className="profit-icon">
                <img src="/icon.jpg" alt="Logo Icon" className="logo-icon" />
            </div>
            <div className="profit-details">
                <span className="profit-label">Profit:</span>
                <span className={`profit-amount ${totalProfitLoss >= 0 ? 'positive' : 'negative'}`}>
                    {formattedProfitLoss}
                </span>
            </div>
        </div>
    );
};

export default TotalProfitLoss;