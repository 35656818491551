import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';

import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import PlansPage from './pages/PlansPage';
import SuccessPage from './pages/SuccessPage';
import CancelPage from './pages/CancelPage';
import Settings from './pages/Settings';
import Home from './pages/HomePage';
import ResultsLandingPage from './pages/ResultsLandingPage';
import UserResultsHistory from './pages/UserResultsHistory';
import PrivacyPage from './pages/PrivacyPage';
import TOS from './pages/TOS';
import PerformancePage from './pages/PerformancePage';

import { trackVisit } from './utils/tracking';
import { AuthProvider } from './context/AuthContext';  // Import AuthProvider
import './css/Global.css';

function App() {

  useEffect(() => {
    // Track the visit on app mount
    trackVisit({
      entryPage: window.location.href,
      referrer: document.referrer,
    });
    
    // Optional cleanup on unmount if you want to track exitPage, etc.
    return () => {
      trackVisit({
        exitPage: window.location.href,
      });
    };

  }, []);

  return (
    <div className="App">
      <AuthProvider>  {/* Wrap the app in AuthProvider */}
        <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/plans" element={<PlansPage />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/cancel" element={<CancelPage />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/home" element={<Home />} />
          <Route path="/results" element={<ResultsLandingPage />} />
          <Route path="/user-results" element={<UserResultsHistory />} />
          <Route path="/privacy-policy" element={<PrivacyPage />} />
          <Route path="/terms-of-service" element={<TOS />} />
          <Route path="/performance" element={<PerformancePage />} />
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
