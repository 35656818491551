import React from 'react';
import '../css/Performance/WelcomeMessage.css'; // Create a CSS file for styling if necessary

const WelcomeMessage = ({ username }) => {
    return (
        <div className="welcome-message">
            <h2>Welcome {username}!</h2>
        </div>
    );
};

export default WelcomeMessage;