import React, { useEffect, useState } from "react";
import Tile from "../components/Tile";
import FilterMenu from "../components/FilterMenu";
import EOI from "../components/EOI"; // Import the EOI component
import "../css/Home.css";

function HomePage() {
  const [tilesData, setTilesData] = useState([]);
  const [displayCount, setDisplayCount] = useState(5);
  const [expandedTileIndex, setExpandedTileIndex] = useState(null);
  const [showEOI, setShowEOI] = useState(false); // State to control the EOI popup

  // State for filters
  const [selectedGame, setSelectedGame] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedSport, setSelectedSport] = useState("");
  const [games, setGames] = useState([]); // List of games for the game filter

  useEffect(() => {
    console.log("Fetching games for the filter menu...");
    fetch("http://localhost:5001/betting/games", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => setGames(data))
      .catch((error) => console.error("Error fetching games:", error));
  }, []);

  useEffect(() => {
    console.log("Fetching data from the API with filters...");
    const params = new URLSearchParams({
      game: selectedGame,
      date: selectedDate,
      sport: selectedSport,
    });

    fetch(`http://localhost:5001/betting/best-odds?${params.toString()}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setTilesData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [selectedGame, selectedDate, selectedSport]);

  // Timer to show EOI popup after 5 seconds
  useEffect(() => {
    console.log("Setting EOI timer...");
    const timer = setTimeout(() => {
      console.log("5 seconds passed, showing EOI popup...");
      setShowEOI(true);
    }, 5000);

    return () => {
      console.log("Clearing EOI timer...");
      clearTimeout(timer); // Cleanup timeout
    };
  }, []);

  const loadMoreTiles = () => {
    setDisplayCount((prevCount) => prevCount + 5);
  };

  const handleTileExpand = (index) => {
    setExpandedTileIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className={`home-container ${showEOI ? "blur-background" : ""}`}>
      {/* Filter Menu Component */}
      <FilterMenu
        games={games}
        selectedGame={selectedGame}
        setSelectedGame={setSelectedGame}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        selectedSport={selectedSport}
        setSelectedSport={setSelectedSport}
      />

      <div className="tiles-container">
        {tilesData.slice(0, displayCount).map((tileData, index) => (
          <Tile
            key={index}
            data={tileData}
            isExpanded={expandedTileIndex === index}
            onExpand={() => handleTileExpand(index)}
          />
        ))}
      </div>
      {displayCount < tilesData.length && (
        <button onClick={loadMoreTiles} className="load-more-button">
          Load More
        </button>
      )}

      {/* Show EOI Popup */}
      {showEOI && <EOI setShowPopup={setShowEOI} />}
    </div>
  );
}

export default HomePage;
