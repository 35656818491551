import React, { useState, useEffect, useCallback } from "react";
import ReactDOM from "react-dom"; // Import React Portal
import "../css/EOI.css";
import { supabase } from "../supabaseClient"; // Import Supabase client

function EOI({ setShowPopup }) {
  const [isVisible, setIsVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });
  const [userIP, setUserIP] = useState(null); // Store user's IP address
  const [userAgent, setUserAgent] = useState(""); // Store user's user-agent

  // In-component notifications
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  // Close Popup Function
  const closePopup = useCallback(() => {
    setIsVisible(false); // Trigger fade-out animation
    setTimeout(() => setShowPopup(false), 300); // Wait for fade-out effect
  }, [setShowPopup]);

  // Fetch User Details and Handle Popup Visibility
  useEffect(() => {
    const initializePopup = async () => {
      setIsVisible(true); // Show popup

      try {
        // Fetch IP Address
        console.log("Fetching user's IP address...");
        let ip = null;
        try {
          const response = await fetch("https://api.ipify.org?format=json");
          const data = await response.json();
          ip = data.ip || null;
          console.log("User IP fetched:", ip);
        } catch (err) {
          console.warn("Failed to fetch IP address:", err.message);
        }

        setUserIP(ip);

        // Set User-Agent
        const agent = navigator.userAgent || "";
        setUserAgent(agent);

        // Check if IP already exists in Supabase
        if (ip) {
          console.log("Checking if IP exists in Supabase...");
          const { data, error } = await supabase
            .from("EOI")
            .select("*")
            .eq("ip", ip);

          if (error) {
            console.error("Error fetching EOI entries:", error.message);
          } else if (data && data.length > 0) {
            console.log("IP already exists in the database. Closing popup.");
            setShowPopup(false);
          }
        }
      } catch (err) {
        console.error("Error initializing popup:", err.message);
      }
    };

    initializePopup();

    // Disable background scrolling
    document.body.style.overflow = "hidden";

    // Close Popup on Escape Key Press
    const handleKeyDown = (e) => {
      if (e.key === "Escape") closePopup();
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [closePopup, setShowPopup]);

  // Overlay Click to Close
  const handleOverlayClick = (e) => {
    if (e.target.className.includes("eoi-overlay")) {
      closePopup();
    }
  };

  // Input Field Handling
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Clear any previous messages
    setErrorMessage(null);
    setSuccessMessage(null);

    try {
      console.log("Submitting data to Supabase:", {
        name: formData.name,
        email: formData.email,
        ip: userIP,
        userAgent,
      });

      const { error } = await supabase.from("EOI").insert([
        {
          name: formData.name,
          email: formData.email,
          ip: userIP || null,
          user_agent: userAgent || "",
          geolocation: "",
          created_at: new Date().toISOString(),
        },
      ]);

      if (error) {
        console.error("Error saving EOI:", error.message);
        setErrorMessage("Failed to submit. Please try again.");
      } else {
        console.log("Form submitted successfully!");
        setSuccessMessage("Expression of interest submitted successfully!");

        // Automatically close popup after a short delay so the user
        // can see the success message
        setTimeout(() => {
          closePopup();
        }, 2000);
      }
    } catch (err) {
      console.error("Unexpected error during form submission:", err.message);
      setErrorMessage("An unexpected error occurred. Please try again.");
    }
  };

  // Popup JSX
  const popup = (
    <div
      className={`eoi-overlay ${isVisible ? "fade-in" : "fade-out"}`}
      onClick={handleOverlayClick}
      role="dialog"
      aria-modal="true"
    >
      <div className="eoi-popup">
        {/* Close Button */}
        <button
          className="eoi-close-button"
          onClick={closePopup}
          aria-label="Close"
        >
          &times;
        </button>

        <h2 id="eoi-title">Your Edge Starts Here</h2>
        <p id="eoi-subetext">
          Be the first to experience a new way to bet, receive signup discounts
          on launch, and gain an edge over the bookies before anyone else.
        </p>

        {/* Notification messages */}
        {errorMessage && <p className="eoi-error">{errorMessage}</p>}
        {successMessage && <p className="eoi-success">{successMessage}</p>}

        <form onSubmit={handleSubmit}>
          {/* If you decide to collect "Name" again, uncomment this input */}
          {/* <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            required
          /> */}

          <input
            type="email"
            name="email"
            placeholder="Email Address"
            value={formData.email}
            onChange={handleChange}
            required
          />

          <div className="eoi-submit-container">
            <button type="submit" className="eoi-submit-button">
              Notify Me
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  // Use Portal to render outside component DOM
  return ReactDOM.createPortal(popup, document.body);
}

export default EOI;
