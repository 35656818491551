import React, { useState } from 'react';
import '../css/DayPass.css'; // Import custom styles for DayPass
import EOI from "../components/EOI"; // Import the EOI popup

const DayPass = () => {
  // State to control popup visibility
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div className="daypass-card-container">
      <div className="daypass-card">
        <div className="glow"></div>
        <h3>DAY PASS</h3>
        <strong>$12</strong>
        <p>
          Experience the full power of our platform—risk-free for 24 hours.
          Unlock every feature and dive in with confidence.
        </p>
        <ul>
          <li>Full access to predictions</li>
          <li>Bet tracking tools</li>
          <li>Community support</li>
        </ul>

        {/* EOI Popup rendered when showPopup is true */}
        {showPopup && <EOI setShowPopup={setShowPopup} />}

        {/* Button to trigger the EOI popup */}
        <button
          className="daypass-button"
          onClick={() => setShowPopup(true)} // Show popup
        >
          Express Your Interest
        </button>
      </div>
    </div>
  );
};

export default DayPass;
