import '../css/Navbar.css';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // Import AuthContext
import { supabase } from '../supabaseClient'; // Import Supabase client

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // For mobile menu toggle
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false); // For user menu dropdown
  const { user, setUser } = useAuth(); // Get user from context
  const navigate = useNavigate();
  const menuRef = useRef(null); // Ref to track outside clicks

  // Check if the user is logged in
  const isLoggedIn = Boolean(user);

  // Listen for auth state changes
  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user || null);
    });

    return () => {
      authListener?.subscription?.unsubscribe(); // Cleanup on unmount
    };
  }, [setUser]);

  // Mobile menu toggle
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close menu if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMenuClick = () => setIsMenuOpen(false); // Close menu on click

  // Toggle user menu dropdown
  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  // Handle sign out
  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error signing out:', error.message);
    } else {
      setUser(null); // Clear user state
      setIsUserMenuOpen(false); // Close dropdown
      localStorage.removeItem("token");
      navigate('/login'); // Redirect to login
    }
  };

  return (
    <header>
      <div className="navbar">
        {/* Logo aligned to the left */}
        <div className="logo">
          <Link to="/">
            <img
              src="/images/logo.png"
              alt="Moolah logo"
              className="logo-full"
            />
            <img
              src="/images/icon.jpg"
              alt="Moolah icon"
              className="logo-icon"
            />
          </Link>
        </div>

        {/* Centered navigation links */}
        <div className="nav-center">
          <ul className={`nav-links ${isMenuOpen ? 'active' : ''}`} ref={menuRef}>
            {!user ? (
              <>
                <li><Link to="/?section=about" onClick={handleMenuClick}>About</Link></li>
                <li><Link to="/?section=features" onClick={handleMenuClick}>Features</Link></li>
                <li><Link to="/?section=pricing" onClick={handleMenuClick}>Pricing</Link></li>
                <li><Link to="/results" onClick={handleMenuClick}>Results</Link></li>
              </>
            ) : (
              <>
                <li><Link to="/home" onClick={handleMenuClick}>Picks</Link></li>
                <li><Link to="/user-results" onClick={handleMenuClick}>My Results</Link></li>
                <li><Link to="/performance" onClick={handleMenuClick}>Performance</Link></li>
              </>
            )}
          </ul>
        </div>

        {/* User Menu for Logged-in Users */}
        {isLoggedIn && (
          <div className="user-icon-container">
            <div className="user-icon" onClick={toggleUserMenu}>👤</div>
            {isUserMenuOpen && (
              <ul className="user-dropdown">
                <li><Link to="/settings">Settings</Link></li>
                <li><button onClick={handleSignOut} className="sign-out-btn">Sign Out</button></li>
              </ul>
            )}
          </div>
        )}

        {/* Mobile menu icon */}
        <div className="menu-icon" onClick={toggleMenu}>
          ☰
        </div>
      </div>
    </header>
  );
}

export default Navbar;
