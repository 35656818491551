import { v4 as uuidv4 } from 'uuid';
import { supabase } from '../supabaseClient'; // Adjust path as needed

/**
 * Gets a cookie by name.
 *
 * @param {string} name - The name of the cookie
 * @returns {string|null} The cookie value or null if not found
 */
function getCookie(name) {
  const match = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
  return match ? match.pop() : null;
}

/**
 * Sets a cookie with the specified name, value, and expiration days.
 *
 * @param {string} name - The name of the cookie
 * @param {string} value - The cookie value
 * @param {number} days - Number of days until the cookie expires
 */
function setCookie(name, value, days) {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}

/**
 * Retrieves (or creates) a unique session ID stored in a cookie.
 *
 * @returns {string} The session ID
 */
function getOrCreateSessionId() {
  const sessionCookieName = 'mooolah_session';
  let sessionId = getCookie(sessionCookieName);

  if (!sessionId) {
    sessionId = uuidv4();
    // Cookie set for ~7 days, adjust as needed
    setCookie(sessionCookieName, sessionId, 7);
  }

  return sessionId;
}

/**
 * Collects basic device/browser data.
 * You can expand or refine this as needed.
 *
 * @returns {object} The collected data
 */
function collectDeviceData() {
  const { userAgent, language, platform } = navigator || {};

  // Basic OS detection
  // (very naive approach, you can get more precise with a library like 'platform.js')
  let os = '';
  if (platform && /Win/.test(platform)) os = 'Windows';
  else if (platform && /Mac/.test(platform)) os = 'Mac';
  else if (platform && /Linux/.test(platform)) os = 'Linux';
  else os = platform || 'Unknown';

  // Basic device type detection
  let deviceType = /Mobi|Android/i.test(userAgent) ? 'Mobile' : 'Desktop';

  // Browser version detection (again, naive)
  // For example, look for Chrome version
  let browser = '';
  let browserVersion = '';
  if (userAgent.indexOf('Chrome/') > -1) {
    browser = 'Chrome';
    browserVersion = userAgent.split('Chrome/')[1]?.split(' ')[0];
  } else if (userAgent.indexOf('Firefox/') > -1) {
    browser = 'Firefox';
    browserVersion = userAgent.split('Firefox/')[1];
  } else if (userAgent.indexOf('Safari/') > -1) {
    browser = 'Safari';
    // Safari version can be trickier because it also includes Chrome in userAgent
    // Simplify for demonstration
    browserVersion = userAgent.split('Version/')[1]?.split(' ')[0];
  } else {
    browser = 'Other';
  }

  // Screen and viewport
  const screenResolution = `${window.screen.width}x${window.screen.height}`;
  const viewportSize = `${window.innerWidth}x${window.innerHeight}`;

  return {
    os,
    device_type: deviceType,
    browser,
    browser_version: browserVersion,
    user_agent: userAgent,
    language,
    screen_resolution: screenResolution,
    viewport_size: viewportSize,
  };
}

/**
 * Tracks page visit data and inserts into Supabase.
 *
 * @param {object} trackingOptions - Additional data about the user's visit
 * @param {string} trackingOptions.entryPage - The entry page URL
 * @param {string} trackingOptions.exitPage - The exit page URL (if known)
 * @param {string} trackingOptions.referrer - The referrer (document.referrer)
 * @param {number} trackingOptions.scrollDepth - The maximum scroll depth
 * @param {number} trackingOptions.visitDuration - Length of visit in seconds
 * @param {number} trackingOptions.pageViews - How many pages were viewed
 */
export async function trackVisit({
  entryPage = window.location.href,
  exitPage = '',
  referrer = document.referrer || '',
  scrollDepth = 0,
  visitDuration = 0,
  pageViews = 1,
}) {
  const sessionId = getOrCreateSessionId();
  const deviceData = collectDeviceData();

  try {
    const { error } = await supabase.from('CookieData').insert([
      {
        // Timestamp is automatically managed if you'd like, but you can also store it explicitly
        timestamp: new Date().toISOString(),
        entry_page: entryPage,
        exit_page: exitPage,
        referrer: referrer,
        scroll_depth: scrollDepth,
        visit_duration: visitDuration,
        page_views: pageViews,
        session_id: sessionId, // from our cookie
        // Device data
        device_type: deviceData.device_type,
        os: deviceData.os,
        browser: deviceData.browser,
        browser_version: deviceData.browser_version,
        screen_resolution: deviceData.screen_resolution,
        viewport_size: deviceData.viewport_size,
        user_agent: deviceData.user_agent,
        language: deviceData.language,
        // Set IP address to null for now
        ip_address: null,
      },
    ]);

    if (error) {
      console.error('Error inserting tracking data:', error);
    } else {
      console.log('Tracking data inserted successfully');
    }
  } catch (err) {
    console.error('Unexpected error inserting tracking data:', err);
  }
}
