import React from 'react';
import '../css/ResultsTable.css'; // CSS file for styling

const removeUnderscores = (value) => {
  if (!value || typeof value !== 'string') return value;
  return value.replace(/_/g, ' ');
};

const ResultsTable = ({ resultsData }) => {
  if (!resultsData || resultsData.length === 0) {
    return <p>No data available to display.</p>;
  }

  return (
    <div className="results-table-container">
      <table className="results-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Round</th>
            <th>Home Team</th>
            <th>Away Team</th>
            <th>Player Name</th>
            <th>Stat Type</th>
            <th>Stat Line</th>
            <th>Odds</th>
            <th>Prediction</th>
            <th>Win/Loss</th>
          </tr>
        </thead>
        <tbody>
          {resultsData.map((result, index) => {
            const outcomeValue = result.WinLoss || result.Outcome;
            let displayOutcome = outcomeValue;
            let backgroundColor = 'transparent'; // default or transparent

            if (outcomeValue && outcomeValue.toLowerCase() === 'yes') {
              displayOutcome = 'Win';
              backgroundColor = 'rgba(28, 167, 51, 0.2)'; // slight green
            } else if (outcomeValue && outcomeValue.toLowerCase() === 'no') {
              displayOutcome = 'Loss';
              backgroundColor = 'rgba(211, 47, 47, 0.2)'; // slight red
            }

            return (
              <tr key={index}>
                <td>{result.Date || result.Games?.GameDate}</td>
                <td>{result.Round || result.Games?.Round}</td>
                <td>{removeUnderscores(result.HomeTeamID || result.Games?.HomeTeamID)}</td>
                <td>{removeUnderscores(result.AwayTeamID || result.Games?.AwayTeamID)}</td>
                <td>{result.Name}</td>
                <td>{removeUnderscores(result.StatType)}</td>
                <td>{result.StatLine}</td>
                <td>${result.OddsValue}</td>
                <td>{result.PredictedOdds || result.Model_probability}</td>
                <td 
                  className={
                    outcomeValue && outcomeValue.toLowerCase() === 'yes'
                      ? 'green-highlight1'
                      : outcomeValue && outcomeValue.toLowerCase() === 'no'
                      ? 'red-highlight1'
                      : ''
                  }
                  style={{ backgroundColor }}
                >
                  {displayOutcome}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ResultsTable;
