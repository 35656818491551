import React from "react";
import { FaDiscord, FaInstagram, FaTiktok } from "react-icons/fa";
import "../css/SocialLinks.css";

const SocialLinks = () => {
  return (
    <div className="social-links-container">
      <div className="social-links">
        <a
          href="https://discord.gg/2nfcNvjFVx"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Join our Discord"
        >
          <FaDiscord className="social-icon" />
        </a>
        <a
          href="https://www.tiktok.com/@mooolah.bet?_t=ZS-8tNZUbaT1gi&_r=1"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Follow us on TikTok"
        >
          <FaTiktok className="social-icon" />
        </a>
        <a
          href="https://www.instagram.com/mooolah.bet?igsh=MWV3bnBoMGxxcDdzYQ%3D%3D&utm_source=qr"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Follow us on Instagram"
        >
          <FaInstagram className="social-icon" />
        </a>
      </div>
    </div>
  );
};

export default SocialLinks;