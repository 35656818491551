import '../css/Hero.css';
import React, { useState } from 'react'; // Import useState for popup state
import EOI from "../components/EOI"; // Import the EOI popup

function Hero() {
  // State to control EOI popup visibility
  const [showPopup, setShowPopup] = useState(false);

  return (
    <section id="hero" className="hero">
      <div className="hero-content">
        <h1 className="hero-heading">MACHINE LEARNING MEETS SPORTS BETTING</h1>
        <p className="hero-subtext">
          Our algorithms analyse past sporting events looking for an edge over the bookmakers
        </p>

        {/* Render EOI Popup when showPopup is true */}
        {showPopup && <EOI setShowPopup={setShowPopup} />}

        {/* Button to trigger EOI popup */}
        <button
          className="hero-button"
          onClick={() => setShowPopup(true)} // Show popup when clicked
        >
          Express Your Interest
        </button>
      </div>

      <div className="hero-phone">
        <img src="/images/mockup.png" alt="Phone Mockup" />
      </div>
    </section>
  );
}

export default Hero;
