import React, { useEffect, useState } from 'react';
import ProfitLossGraph from '../components/ProfitLossGraph';
import WinLossPieChart from '../components/WinLossPieChart';
import TotalProfitLoss from '../components/TotalProfitLoss';
import WelcomeMessage from '../components/WelcomeMessage';
import StatTypeRadarChart from '../components/RadarChart';
import UnitBetInput from '../components/UnitBetInput';
import RiskAnalysis from "../components/RiskAnalysis";
import Spinner from '../components/Spinner';
import '../css/PerformancePage.css';

import { supabase } from '../supabaseClient';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const PerformancePage = () => {
    const [userData, setUserData] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [unitBet, setUnitBet] = useState(1); // Default unit bet amount

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const { data } = await supabase.auth.getUser();
                const user = data.user; // Correctly get the user object
                console.log("Fetched user:", user); // Log user data
        
                if (user) {
                    const { data: profileData, error: profileError } = await supabase
                        .from('profiles')
                        .select('username, phone')
                        .eq('user_id', user.id)
                        .single();
        
                    if (profileError) throw profileError;
        
                    setUserData({
                        id: user.id,
                        username: profileData.username,
                        phone: profileData.phone,
                        email: user.email,
                    });
        
                    console.log("User data set in state:", {
                        id: user.id,
                        username: profileData.username,
                        phone: profileData.phone,
                        email: user.email,
                    });
                }
            } catch (error) {
                console.error('Error fetching user data:', error.message);
            }
        };

        const fetchPerformanceData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${BACKEND_URL}/api/results/user-results`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch performance data');
                }

                const result = await response.json();
                setData(result.data || []);
            } catch (error) {
                console.error('Error fetching performance data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
        fetchPerformanceData();
    }, []);

    return (
        <div className="performance-page">
            <div className="top-row">
                <div className="performance-card welcome-box">
                    {userData && <WelcomeMessage username={userData.username} />}
                </div>
                <div className="performance-card unit-bet-box">
                    <UnitBetInput unitBet={unitBet} setUnitBet={setUnitBet} />
                </div>
                <div className="performance-card profit-tile-container">
                    <TotalProfitLoss data={data} unitBet={unitBet} />
                </div>
            </div>
            {loading ? (
                <Spinner />
            ) : (
                <div className="performance-content">
                    <div className="performance-card graph-container">
                        <ProfitLossGraph data={data} unitBet={unitBet} />
                    </div>
                    <div className="performance-card pie-chart-container">
                        <WinLossPieChart data={data} />
                    </div>
                    <div className="performance-card radar-chart-container">
                        <StatTypeRadarChart data={data} />
                    </div>
                    <div className="performance-card risk-analysis-container">
                        <RiskAnalysis data={data} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default PerformancePage;